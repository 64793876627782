import { toJS } from 'mobx';

import { CareUnit } from 'api/careUnitsApi';

import { CareProvidersListItem } from './stores/types';

/**
 * Traverse the care provider tree and add care unit permissions, and also removes care units and care provider that user doesn't have access to when filter is turned on.
 * @param careProviders
 * @param careUnitPredicateFn
 * @param careProviderPredicateFn
 * @param filter
 * @returns
 */
export const traverseCareProviderTreeAndAddCareunitPermissions = (
  careProviders: CareProvidersListItem[],
  careUnitPredicateFn: (careProvider: string, careUnitId: string) => boolean,
  careProviderPredicateFn: (careProvider: string) => boolean,
  filter = false
) => {
  let updatedCareProviders = toJS(careProviders).map(careProvider => {
    if (careProvider.careUnits && careProvider.careUnits.length > 0) {
      const children = careProvider.careUnits.map((careUnit: CareUnit) => ({
        ...careUnit,
        careProviderId: careProvider.id,
        canView: careUnitPredicateFn(careProvider.id, careUnit.id),
      }));

      careProvider.children = filter
        ? children.filter((careUnit: CareProvidersListItem) => careUnit.canView)
        : children;
    }

    careProvider.canView = careProviderPredicateFn(careProvider.id);
    return careProvider;
  });

  if (filter) {
    updatedCareProviders = updatedCareProviders.filter(
      careProvider =>
        careProvider.canView || careProvider.children?.some(careUnit => careUnit.canView)
    );
  }

  return sortCareProviderCareUnitList(updatedCareProviders);
};

export const getInitialExpandedRowKeys = (
  data: CareProvidersListItem[],
  careUnitPredicateFn: (careProvider: string, careUnitId: string) => boolean,
  careProviderPredicateFn: (careProvider: string) => boolean
) => {
  const expandedRowKeys: string[] = [];
  toJS(data)?.forEach(careProvider => {
    if (careProvider?.careUnits && careProvider?.careUnits.length > 0) {
      if (
        careProviderPredicateFn(careProvider.id) ||
        careProvider.careUnits?.some((careUnit: CareUnit) =>
          careUnitPredicateFn(careProvider.id, careUnit.id)
        )
      ) {
        expandedRowKeys.push(careProvider.id);
      }
    }
  });

  return expandedRowKeys;
};

export const sortCareProviderCareUnitList = (data: CareProvidersListItem[]) => {
  return data
    .map((careProvider: CareProvidersListItem) => {
      if (careProvider.children && careProvider.children?.length > 0) {
        careProvider.children.sort((a, b) => {
          if (a.canView !== b.canView) {
            return a.canView ? -1 : 1;
          }
          return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' });
        });
      }
      return careProvider;
    })
    .sort((a, b) => {
      if (a.canView !== b.canView) {
        return a.canView ? -1 : 1;
      }
      return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' });
    });
};

export const filterCareUnits = (
  careUnits: CareProvidersListItem[] = [],
  searchTerm: string,
  name: string
) => {
  return (
    careUnits?.filter(
      (careUnit: CareProvidersListItem) =>
        `${name} ${careUnit.name}`.toLowerCase().includes(searchTerm) ||
        careUnit.externalOrgId.toLowerCase().includes(searchTerm)
    ) || []
  );
};
